// Dark theme - TEMA PRINCIPAL
html[data-theme="dark"] {
  --color-bg-0: #1f1f1f; // Cor do fundo da aplicação
  --color-bg-1: #282828; // Cor do fundo dos cards, header
  --color-bg-2: #393939; // Cor do fundo de elementos sobre bg 1
  --color-bg-3: #4a4a4a; // Cor do fundo de elementos sobre bg 2
  --color-success: #25b84c; // Cor verde de sucesso (confirmação/conclusão/etc...)
  --color-success-disabled: #2c5036; // Cor verde sucesso quando desativado
  --color-warning: #e13d3c; // Cor vermelha de aviso (reprovação/cancenlamento/etc...)
  --color-attention: #fac25d; // Cor amarela de atenção (botão de reabrir etapa...)
  --color-extras: #2364aa; // Cor azul de extras (informação/extra/auxiliar)
  --color-accent: #007cd8; // Cor de acentuação (checkbox, por exemplo)
  --color-fg-0: #eeeeee; // Cor principal das fontes
  --color-fg-1: #aaaaaa; // Cor secundária das fontes
  --color-fg-2: #8e8e8e; // Cor label input
  --color-fg-3: #6b6b6b; // Cor para pouco destaque em fontes, por exemplo
  --color-fg-inv: #333333; // Cor contrastante com a principal do FG
  --color-icon: #eeeeee; // Cor dos ícones/texto sobre fundos coloridos (ícones do stepper, por exemplo)
  --color-icon-inv: #eeeeee; // Cor dos ícones contrastantes com o fundo (ícone do usuário no header, por exemplo)
  --color-transparent: transparent; // Transparência
  --color-backdrop: rgba(
    255,
    255,
    255,
    0.3
  ); // Cor do backdrop quando um modal é aberto
  --color-neon: #00ddca; // Cor azul neon
  --color-fg-light: #eeeeee; // Cor foreground clara (utilizado em casos como botões com fundo colorido)
  --color-fg-dark: #333333; // Cor foreground escura
  --color-subtitle: #d1ccccd9;
  --color-label: #d5d5da;
  --color-card: #6b6b6b;
  --color-drawer: #4a4a4a;
  --color-toolbar: #015326;
  --mat-app-primary: #eeeeee;
  --mdc-text-button-label-text-color: var(--mat-app-primary);
  --mat-icon-color: var(--mat-app-primary);
  --mdc-outlined-button-label-text-color: var(--mat-app-primary);
  --mdc-filled-text-field-container-color: #ffffff00;
  --mat-switch-label-text-color: var(--color-fg-0);
  --mdc-outlined-card-container-color: var(--color-card) !important;
  --mdc-elevated-card-container-color: var(--color-card) !important;
  --mdc-filled-button-label-text-color: var(--color-fg-0) !important;
  --mdc-filled-button-container-color: var(--color-bg-0) !important;
  --mat-sidenav-content-background-color: var(--color-drawer) !important;
}

// Light theme
html[data-theme="light"] {
  --color-bg-0: #ffffff;
  --color-bg-1: #efefef;
  --color-bg-2: #d5d5da;
  --color-bg-3: #c4c4c9;
  --color-success: #25b84c;
  --color-success-disabled: #2c5036;
  --color-warning: #e13d3c;
  --color-attention: #fac25d;
  --color-extras: #2364aa;
  --color-accent: #007cd8;
  --color-fg-0: #1f1f1f;
  --color-fg-1: #252525;
  --color-fg-2: #4a4a4a;
  --color-fg-3: #8e8e8e;
  --color-fg-inv: #333333;
  --color-icon: #ffffff;
  --color-icon-inv: #252525;
  --color-transparent: transparent;
  --color-backdrop: rgba(0, 0, 0, 0.7);
  --color-neon: #00ddca;
  --color-fg-light: #eeeeee;
  --color-fg-dark: #333333;
  --color-subtitle: rgba(0, 0, 0, 0.54);
  --color-label: #393939;
  --color-card: #fff;
  --color-drawer: #f7f7f7;
  --color-toolbar: #189952;
  --mat-app-primary: #1f1f1f;
  --mdc-text-button-label-text-color: var(--mat-app-primary);
  --mat-icon-color: var(--mat-app-primary);
  --mdc-outlined-button-label-text-color: var(--mat-app-primary);
  --mdc-filled-text-field-container-color: #ffffff00;
  --mdc-outlined-card-container-color: var(--color-card) !important;
  --mdc-elevated-card-container-color: var(--color-card) !important;
  --mdc-filled-button-label-text-color: var(--color-fg-0) !important;
  --mdc-filled-button-container-color: var(--color-bg-0) !important;
  --mat-sidenav-content-background-color: var(--color-drawer) !important;
}

html .mat-mdc-slide-toggle {
  --mat-switch-label-text-color: var(--color-fg-0) !important;
}

.mat-mdc-table {
  background-color: inherit !important;
}

.mat-mdc-menu-content {
  background-color: var(--color-bg-1) !important;
}
