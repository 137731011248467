@import "assets/scss/main.scss";

.mat-tab-label .mat-tab-label-content {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  color: var(--color-fg-0);
}

.mat-tab-group {
  margin-bottom: 48px;
}
