@use '@angular/material' as mat;
@use './m3-theme.scss' as m3;

@include mat.core();

html {
  @include mat.core-theme(m3.$light-theme);
  @include mat.button-theme(m3.$light-theme);
  @include mat.all-component-themes(m3.$light-theme);
  @include mat.system-level-colors(m3.$light-theme);
  @include mat.system-level-typography(m3.$light-theme);
  // MatDatePicker
  --mat-datepicker-calendar-container-background-color: #fff;
  --mat-datepicker-calendar-container-elevation-shadow: 0px 0px 0px 1px
      rgba(0, 0, 0, 0.2),
    0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mat-datepicker-calendar-header-divider-color: var(
    --sys-secondary-container
  );
}

html[data-theme="dark"] {
  @include mat.core-color(m3.$dark-theme);
  @include mat.button-color(m3.$dark-theme);
  @include mat.all-component-colors(m3.$dark-theme);
  @include mat.system-level-colors(m3.$dark-theme);
  @include mat.system-level-typography(m3.$dark-theme);

  // MatDatePicker
  --mat-datepicker-calendar-header-divider-color: var(
    --sys-secondary-container
  );
}
