@import "./colors"; // Arquivo com as paletas de cores

/* ---------- 1) Gerais (Fundo da página inteira, documento completo, fontes, etc...) ---------- */

// Mudança geral de fonte
* {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

// Regras gerais para o documento
body {
  background-color: var(--color-bg-1);
  color: var(--color-fg-0); // TODO: Verificar se isso aqui vai dar certo
}

// Classe para transição de mudança de tema
html.transition,
html.transition *,
html.transition *:before,
html.transition *:after {
  transition: all 450ms !important;
  transition-delay: 0 !important;
}

// Classe container base para diversos componentes
.container {
  position: relative;
  top: 29px;
}

// Classe container step
.container-step {
  padding-top: 20px;
}

// Scrollbar (??)
#style-3::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}

// Scrollbar (??)
#style-3::-webkit-scrollbar {
  width: 6px;
  background-color: #f5f5f5;
}

// Scrollbar (??)
#style-3::-webkit-scrollbar-thumb {
  background-color: #000000;
}

// Scrollbar (??)
::-webkit-scrollbar {
  width: 7px;
}

// Scrollbar (??)
::-webkit-scrollbar-track {
  border-radius: 7px;
}

// Scrollbar (??)
::-webkit-scrollbar-thumb {
  background: #b3c7cb;
  border-radius: 7px;
}

// Scrollbar (??)
::-webkit-scrollbar-thumb:hover {
  background: gray;
}

// Texto Headings h3
h3 {
  color: var(--color-fg-1);
}

// Texto Headings h2 e h5
h2,
h5 {
  color: var(--color-fg-0);
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

// Texto de links
a {
  // color: #00ddca;
  //color: $color-neon;
  color: var(--color-accent);
}

// Linha horizontal comum
hr {
  width: 100%;
}

.unstyled {
  -webkit-appearance: none;
}

// (??)
.unstyled::-webkit-inner-spin-button,
.unstyled::-webkit-calendar-picker-indicator {
  display: none;
  -webkit-appearance: none;
}

// (??)
fieldset {
  padding: 0;
  padding-top: 2vh;
}

/* ---------- 2) Botões personalizados ---------- */

button.row-button {
  margin: 0px;
  min-width: 100px;
  padding: 2px;
  margin-bottom: 16px;
}

// Botão Primário (Sucesso, concluir ação, avançar...)
.btn-primary {
  background-color: var(--color-accent) !important;
  text-transform: uppercase !important;
}

// Texto do botão Primário
.btn-primary {
  color: var(--color-fg-light) !important;
  font-weight: bold !important;
  text-transform: uppercase !important;
}

// Botão Secundário (Cancelar uma ação, sair de dialog sem fazer ação alguma...)
.btn-secondary {
  border: 2px solid var(--color-fg-0) !important;
}

// Texto do botão Secundário
.btn-secondary {
  font-weight: bold !important;
  color: var(--color-fg-0) !important;
  text-transform: uppercase !important;
}

// Botão Danger (Excluir processo, cancelar...)
.btn-danger {
  background-color: var(--color-warning) !important;
  text-transform: uppercase !important;
}

// Texto do botão Danger
.btn-danger {
  color: var(--color-fg-light) !important;
  font-weight: bold !important;
  text-transform: uppercase !important;
}

// Botão Atenção (Reabrir etapa...)
.btn-warn {
  background-color: var(--color-attention) !important;
}

// Texto do botão Atenção
.btn-warn {
  font-weight: bold !important;
  text-transform: uppercase !important;
  color: var(--color-fg-dark) !important;
}

// Botão Extra (Funções adicionais, como Upload de arquivo...)
.btn-extra {
  background-color: var(--color-accent) !important;
}

// Texto do botão Extra
.btn-extra {
  font-weight: bold !important;
  color: var(--color-fg-light) !important;
}

/* ---------- 3) Componentes Angular / Material ---------- */

/** ---------- 3.1) Inputs de texto ---------- **/

// Input de texto (Cor da fonte e do indicador de posição no texto)
.mat-input-element {
  color: var(--color-fg-0) !important;
  caret-color: var(--color-fg-1);
}

// Fonte placeholders de input de texto (posição original)
.mat-form-field-label {
  color: var(--color-fg-1) !important;
  font-size: 16px !important;
}

// Fonte placeholders de input de texto (posição float)
mat-form-field.mat-form-field-should-float label {
  color: var(--color-fg-1) !important;
  font-size: 18px !important;
}

// Linha sob os inputs de texto
mat-form-field.mat-form-field.mat-form-field-appearance-legacy
  .mat-form-field-underline {
  background-color: var(--color-fg-0);
}

// Linha sob os inputs de texto quando estão em foco
.mat-form-field.mat-focused .mat-form-field-ripple {
  background-color: var(--color-accent) !important;
}

/** ---------- 3.2) Table ---------- **/

// Padrão geral de tabelas
table.mat-table {
  width: 100%;
  //background-color: #27283c;
  background-color: var(--color-bg-1);
  overflow-x: scroll;
  table-layout: fixed;
}

// Padrão geral de cabeçalho de tabelas
table.mat-table thead th {
  //color: #c4c0c0;
  color: var(--color-fg-0) !important;
  font-size: 0.7em !important;
}

// Padrão geral de conteúdo de tabelas
table.mat-table tbody td {
  //color: #c4c0c0;
  color: var(--color-fg-0) !important;
  font-size: 0.7em !important;
}

// Hover nas linhas da tabela principal (tabela da home)
.row-table:hover {
  background-color: var(--color-bg-3);
  cursor: pointer;
}

// Cor das linhas que separam linhas nas tabelas
mat-footer-row,
mat-header-row,
mat-row,
td.mat-cell,
td.mat-footer-cell,
th.mat-header-cell {
  // border-bottom-color: #c4c0c0;
  border-bottom-color: var(--color-fg-1);
}

/** ---------- 3.3) Expansion panels ---------- **/

// Conteúdo dos Expansion Panels (Praticamente todas as informações no card do processo)
.mat-expansion-panel.card {
  background: transparent;
  color: var(--color-fg-0);
}

// Expansion panel aberto
.mat-expansion-panel.card:not([class*="mat-elevation-z"]) {
  box-shadow: none;
}

// Título dos expansion panels
.mat-expansion-panel-header-title.card {
  //color: #c0c0c4;
  color: var(--color-fg-1);
}

// Descritivo dos expansion panels e setinha
.card.mat-expansion-indicator::after,
.card.mat-expansion-panel-header-description {
  // color: #c0c0c4;
  color: var(--color-fg-1);
}

// Header dos expansion panels
.card.mat-expansion-panel-header {
  height: 40px !important;
  font-size: 14px;
}

/** ---------- 3.4) Checkboxes ---------- **/

// Geral checkbox
.mat-checkbox {
  display: inline-flex;
  margin: 1px;
  margin-bottom: 10px;
}

// Cor do checkbox marcado
.mat-checkbox-checked.mat-accent .mat-checkbox-background,
.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background {
  //background-color: #00ddca;
  background-color: var(--color-accent);
}

// Fonte do label do checkbox
.mat-checkbox-label {
  font-size: 14px;
  //color: #9f9f9f;
  color: var(--color-fg-0);
}

// Frame do checkbox
.mat-checkbox-frame {
  // border-color: #c0c0c4;
  border-color: var(--color-fg-1);
}

// Label dos checkbox
.mat-checkbox-layout .mat-checkbox-label {
  line-height: 20px;
}

// Layout checkbox
.mat-checkbox-layout {
  max-width: 100% !important;
}

// Label dos checkbox
.mat-checkbox-label {
  max-width: 100% !important;
}

// Layout de checbox
.mat-checkbox .mat-checkbox-layout {
  max-width: 100% !important;
}

// Adição de reticências quando o label do checkbox ultrapassar o tamanho previsto do container
.mat-checkbox .mat-checkbox-label {
  line-height: 20px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

/** ---------- 3.5) Buttons ---------- **/

// Botão geral do material
button.mat-button.btn {
  margin: 10px;
  min-width: 120px;
  padding: 5px;
}

// Texto de alguns botões (como o nome do usuário sobre o menu do usuário)
span.mat-button-wrapper {
  color: var(--color-fg-0);
}

// Botão pequeno (Busca por referências, Busca por requisitantes...)
.mat-fab.mat-accent,
.mat-flat-button.mat-accent,
.mat-mini-fab.mat-accent,
.mat-raised-button.mat-accent {
  background-color: var(--color-extras) !important;
}

/** ---------- 3.6) Material Menus ---------- **/

.mat-menu-panel {
  //background: #27283c;
  //background: #282828;
  background-color: var(--color-layer-2);
}

// Cor dos itens do mat-menu (por exemplo o menu do usuário no canto superior direito da aplicação)
.mat-menu-item {
  // color: white;
  color: var(--color-fg-0);
  background-color: var(--color-bg-2);
}

// Cor dos itens do mat-menu quando é feito um hover
.mat-menu-item:hover {
  // color: white;
  color: var(--color-fg-0);
  background-color: var(--color-bg-3) !important;
}

/** ---------- 3.7) Material paginator ---------- **/

.mat-paginator {
  //background: $color-primary;
  background-color: var(--color-bg-0);
}

.mat-paginator,
.mat-paginator-page-size .mat-select-trigger {
  // color: white;
  color: var(--color-fg-0);
}

/** ---------- 3.8) Material selects ---------- **/

// Seta dos inputs selects
.mat-select-arrow {
  // color: white;
  color: var(--color-fg-0);
}

// Painel de um input de select
.mat-select-panel {
  background-color: var(--color-bg-2);
}

// Opção de input select em hover
.mat-select-panel .mat-option:hover {
  background-color: var(--color-bg-3);
}

// Checkbox em selects
.mat-pseudo-checkbox {
  color: var(--color-fg-0);
}

// Opções do mat select
.mat-option {
  color: var(--color-fg-0);
}

// Opção ativa do mat-select
.mat-option.mat-active {
  color: var(--color-fg-0);
  background-color: transparent;
}

// Label dos checkbox no mat select
.mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: var(--color-fg-0);
}

// Preenchimento do checkbox do mat-select
.mat-primary .mat-pseudo-checkbox-checked,
.mat-primary .mat-pseudo-checkbox-indeterminate {
  background-color: var(--color-accent);
}

// Texto das opções selecionadas no mat-select
.mat-select-value {
  color: var(--color-fg-0);
}

// Borda extra nos input select
.mat-menu-content:not(:empty) {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

/** ---------- 3.9) Material icons ---------- **/

// Icones gerais da aplicação
mat-icon {
  //color: #d4d4d8;
  color: var(--color-icon);
}

/** ---------- 3.10) Material Stepper ---------- **/

// Cor de fundo do stepper, setado como transparente para não causar impacto algum
.mat-stepper-horizontal,
.mat-stepper-vertical {
  //background-color: #27283c;
  //background-color: #282828;
  background-color: transparent;
}

// Label dos steps
.mat-step-header .mat-step-label.mat-step-label-active {
  //color: #ecedef;
  color: var(--color-fg-0);
}

// Label dos steps que ainda não foram concluídos
.mat-step-header .mat-step-label,
.mat-step-header .mat-step-optional {
  color: var(--color-fg-0);
}

// Wrapper dos textos dos labels
.mat-step-text-label {
  text-overflow: ellipsis;
  overflow: hidden;
  margin-left: 5px;
  font-size: larger;
}

// Ícone de step inacessível
.mat-step-header .mat-step-icon {
  // background-color: #888888;
  background-color: var(--color-fg-3);
  margin-right: 13px;
  color: var(--color-fg-0);
  height: 40px;
  width: 40px;
  font-size: 30px;
}

// Ícone do step em edição
.mat-step-header .mat-step-icon-state-edit {
  // background-color: #4162be;
  background-color: var(--color-accent);
}

// Ícone do step já concluído
.mat-step-header .mat-step-icon-state-done {
  // background-color: #13ac05;
  background-color: var(--color-success);
}

// Linha do Stepper quando não concluído
.mat-stepper-horizontal-line {
  border-top-width: 1px;
  border-top-style: solid;
  flex: auto;
  height: 0;
  margin: 0 -16px;
  min-width: 32px;
  border-color: var(--color-fg-2);
}

/** ---------- 3.11) Modal ---------- **/

// Classe base dos modal
.custom-modalBox {
  mat-dialog-container {
    padding: 0;
    color: var(--color-fg-0);
    background-color: var(--color-bg-0);
  }
}

/** ---------- 3.12) Material Dialogs ---------- **/

// Classe base dialog
.mat-dialog-container {
  //background: #1a1b29;
  background-color: var(--color-bg-0);
  padding-top: 0px;
}

// Dialog picker (??)
.mat-dialog-container .md-drppicker {
  position: fixed;
}

// Dialog drop down (??)
.md-drppicker.drops-down-auto.ltr.shown.double {
  top: 29% !important;
}

// Conteúdo do mat-dialog
.mat-dialog-content {
  max-height: 90% !important;
}

/** ---------- 3.13) Material Datepicker ---------- **/

// Fundo do datepicker
.mat-calendar {
  background-color: var(--color-bg-2);
}

// Seta do botão de selecionar o mês
.mat-calendar-arrow {
  border-top-color: var(--color-fg-1);
}

// Setas de seleção de mês (previous e next)
.mat-datepicker-content .mat-calendar-next-button,
.mat-datepicker-content .mat-calendar-previous-button,
.mat-datepicker-toggle {
  color: var(--color-fg-1);
}

// Fonte dos dias da semana do datepicker
.mat-calendar-table-header {
  color: var(--color-fg-1);
}

// Linha divisora do header do datepicker
.mat-calendar-table-header-divider::after {
  background-color: var(--color-fg-1);
}

// Label do mês selecionado do datepicekr
.mat-calendar-body-label {
  color: var(--color-fg-0);
}

// Fontes do grid de dias no datepicker
.mat-calendar-body-cell-content {
  color: var(--color-fg-0);
}

// Indicador de dia selecionado
.mat-calendar-body-selected {
  background-color: var(--color-extras);
  color: var(--color-icon);
}

/** ---------- 3.14) Material Radio buttons ---------- **/

// Círculo externo radio button não selecionado
.mat-radio-button.mat-accent .mat-radio-outer-circle {
  // border-color: #dcdcdc;
  border-color: var(--color-fg-0);
  height: 15px;
  width: 15px;
  left: 3px;
  top: 2px;
}

// Círculo externo radio button selecionado
.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  // border-color: #00ddca;
  border-color: var(--color-accent);
  height: 15px;
  width: 15px;
  left: 3px;
  top: 2px;
}

// Preenchimento fade-out quando altera o radio button selecionado
.mat-radio-button.mat-accent .mat-radio-inner-circle {
  color: var(--color-bg-0);
  background-color: var(--color-bg-0);
  height: 15px;
  width: 15px;
  left: 3px;
  top: 2px;
}

// Círculo preenchido do radio button selecionado
.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-inner-circle {
  // background-color: #00ddca;
  background-color: var(--color-accent);
  height: 15px;
  width: 15px;
  left: 3px;
  top: 2px;
}

// Cor do efeito ripple quando um radio button é selecionado
.mat-radio-button.mat-accent.mat-radio-checked
  .mat-radio-ripple
  .mat-ripple-element {
  // background-color: #00ddca;
  background-color: var(--color-accent);
}

/** ---------- 3.15) Mat tab ---------- **/

// Barra inferior no label das tabs (existentes no modal referencia, por exemplo)
.mat-tab-group.mat-primary .mat-ink-bar,
.mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: var(--color-fg-2);
}

// Badge de notificação (itens selecionados na tab de pedidos no modal referencia, por exemplo)
.mat-badge-content {
  color: var(--color-fg-0) !important;
  background-color: var(--color-warning) !important;
}

// Conteúdo do mat-tab
.mat-tab-body-content {
  overflow: hidden !important;
}

/** ---------- 3.16) Date/Timepicker ---------- **/

// Linha que separa o cabeçalho do corpo do timepicker de log
.owl-dt-calendar-table
  .owl-dt-calendar-header
  .owl-dt-calendar-table-divider:after {
  background-color: var(--color-fg-2);
}

// Corpo do timepicker de log
.owl-dt-container,
.owl-dt-container * {
  background-color: var(--color-bg-2);
  color: var(--color-fg-0);
}

// Números na grid do timepicker de log
.owl-dt-calendar-table .owl-dt-calendar-cell-content {
  color: var(--color-fg-0);
}

// Textos no timepicker de log
.owl-dt-control .owl-dt-control-content {
  color: var(--color-fg-1);
}

// Dia selecionado no timepicker de log
.owl-dt-calendar-table .owl-dt-calendar-cell-selected {
  background-color: var(--color-extras);
  color: var(--color-icon);
}

/** ---------- 3.17) Date range picker ---------- **/

// Corpo principal do date range picker dos filtros da home screen
.md-drppicker {
  background-color: var(--color-bg-2) !important;
  color: var(--color-fg-0) !important;
}

// Corpo do calendário do date range picker
.md-drppicker .calendar-table {
  border-color: var(--color-bg-2) !important;
  background-color: var(--color-bg-2) !important;
}

// Números de meses que não são o atual do date range picker
.md-drppicker td.off,
.md-drppicker td.off.end-date,
.md-drppicker td.off.in-range,
.md-drppicker td.off.start-date {
  color: var(--color-fg-2) !important;
  background-color: transparent !important;
}

// Extremos do intervalo selecionado do date range picker
.md-drppicker td.active,
.md-drppicker td.active:hover {
  background-color: var(--color-extras) !important;
  color: var(--color-icon) !important;
}

// intervalo selecionado do date range picker
.md-drppicker td.in-range {
  background-color: var(--color-accent) !important;
  color: var(--color-icon) !important;
}

// Cabeçalhos do rangepicker do date range picker
.md-drppicker th {
  color: var(--color-fg-1) !important;
}

// Botão ativo de seleção de intervalo pré-definido do date range picker
.md-drppicker .ranges ul li button.active {
  background-color: var(--color-extras) !important;
  color: var(--color-icon) !important;
}

// Botões inativos de seleção de intervalo pré-definido do date range picker
.md-drppicker .ranges ul li button {
  color: var(--color-fg-0) !important;
}

// Botões inativos de seleção de intervalo pré-definido (hover) do date range picker
.md-drppicker .ranges ul li button:hover {
  color: var(--color-fg-0) !important;
  background-color: var(--color-fg-3) !important;
}

// Botão de filtrar do date range picker
.md-drppicker .btn {
  background-color: var(--color-accent) !important;
  color: var(--color-icon) !important;
}

// Botão de cancelar o filtro do date range picker
.md-drppicker .btn.btn-default {
  background-color: var(--color-transparent) !important;
  color: var(--color-fg-0) !important;
  right: 15px !important;
}

// Icone do botão de cancelar filtro do date range picker
.md-drppicker .clear svg {
  display: none !important;
}

/* ---------- 4) Dinâmica do Stepper ---------- */

@mixin styleStepLine($index) {
  .mat-horizontal-stepper-header {
    & + .mat-stepper-horizontal-line:nth-child(#{$index}) {
      // border-color: #13ac05;
      border-color: var(--color-success);
      border-width: 1px;
    }
  }
}

@mixin styleStepSelected($index) {
  .mat-horizontal-stepper-header:nth-child(#{$index}) {
    // border-bottom: 4px solid #00ddca;
    border-bottom: 4px solid var(--color-accent);
    margin-bottom: -4px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
  }
}

@mixin customBackGroundEditStep() {
  .mat-step-header .mat-step-icon-state-edit {
    // background-color: #13ac05;
    background-color: var(--color-accent);
  }
}

.customEditStep {
  @include customBackGroundEditStep();
}

.selected-step-border-0 {
  @include styleStepSelected(1);
}

.selected-step-border-1 {
  @include styleStepSelected(3);
}

.selected-step-border-2 {
  @include styleStepSelected(5);
}

.selected-step-border-3 {
  @include styleStepSelected(7);
}

.last-edited-step-1 {
  @include styleStepLine(2);
}

.last-edited-step-2 {
  @include styleStepLine(2);
  @include styleStepLine(4);
}

.last-edited-step-3 {
  @include styleStepLine(2);
  @include styleStepLine(4);
  @include styleStepLine(6);
}

.mat-stepper-label-position-bottom
  .mat-horizontal-stepper-header:not(:first-child)::before,
.mat-stepper-label-position-bottom
  .mat-horizontal-stepper-header:not(:last-child)::after,
[dir="rtl"]
  .mat-stepper-label-position-bottom
  .mat-horizontal-stepper-header:not(:first-child)::after,
[dir="rtl"]
  .mat-stepper-label-position-bottom
  .mat-horizontal-stepper-header:not(:last-child)::before {
  width: 0 !important;
}

.mat-step-header .mat-step-header-ripple {
  display: none;
}

// Efeito no background do step durante hover do mouse
.mat-step-header.cdk-keyboard-focused,
.mat-step-header.cdk-program-focused,
.mat-step-header:hover {
  background-color: rgba(31, 158, 180, 0.116);
  border-radius: 15px;
}

.mat-stepper-label-position-bottom .mat-horizontal-stepper-header {
  padding: 8px 0 8px 0 !important;
  width: 35px !important;
}

.mat-stepper-label-position-bottom .mat-stepper-horizontal-line {
  top: 20px !important;
}

/** ---------- 5) Floating button home screen ---------- */

// Floating button na home-screen
#floating-button {
  width: 55px;
  height: 55px;
  border-radius: 50%;
  // background: #00ddca;
  background: var(--color-accent);
  position: fixed;
  bottom: 30px;
  right: 30px;
  cursor: pointer;
  box-shadow: 0px 2px 5px #666;
}

// Icone do floating button
.floating-button-icon {
  color: var(--color-icon);
}

// Sinal de + do floating button
.plus {
  // color: white;
  color: var(--color-icon);
  position: absolute;
  top: 0;
  display: block;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
  padding: 0;
  margin: 0;
  line-height: 55px;
  font-size: 38px;
  font-family: "Roboto";
  font-weight: 300;
  animation: plus-out 0.3s;
  transition: all 0.3s;
}

// Container do floating button
#container-floating {
  position: fixed;
  width: 70px;
  height: 70px;
  bottom: 30px;
  right: 30px;
  z-index: 50px;
}

// Hover no container do floating button
#container-floating:hover {
  height: 400px;
  width: 90px;
  padding: 30px;
}

// Animação do sinal de + no floating button em hover
#container-floating:hover .plus {
  animation: plus-in 0.15s linear;
  animation-fill-mode: forwards;
}

// Animação do flaoting button para retornar a posição original
.edit {
  position: absolute;
  top: 0;
  display: block;
  bottom: 0;
  left: 0;
  display: block;
  right: 0;
  padding: 0;
  opacity: 0;
  margin: auto;
  line-height: 65px;
  transform: rotateZ(-70deg);
  transition: all 0.3s;
  animation: edit-out 0.3s;
}

// Container do floating button em hover  quando está aberto
#container-floating:hover .edit {
  animation: edit-in 0.2s;
  animation-delay: 0.1s;
  animation-fill-mode: forwards;
}

// Animação do floating button
@keyframes edit-in {
  from {
    opacity: 0;
    transform: rotateZ(-70deg);
  }
  to {
    opacity: 1;
    transform: rotateZ(0deg);
  }
}

// Animação do floating button
@keyframes edit-out {
  from {
    opacity: 1;
    transform: rotateZ(0deg);
  }
  to {
    opacity: 0;
    transform: rotateZ(-70deg);
  }
}

// Animação do floating button
@keyframes plus-in {
  from {
    opacity: 1;
    transform: rotateZ(0deg);
  }
  to {
    opacity: 0;
    transform: rotateZ(180deg);
  }
}

// Animação do floating button
@keyframes plus-out {
  from {
    opacity: 0;
    transform: rotateZ(180deg);
  }
  to {
    opacity: 1;
    transform: rotateZ(0deg);
  }
}

// Botões menores junto ao floating button
.nds {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  position: fixed;
  z-index: 300;
  transform: scale(0);
  cursor: pointer;
}

// Classe de alinhamento dos ícones dos floating buttons
.letter {
  font-size: 23px;
  font-family: "Roboto";
  position: absolute;
  left: 0;
  right: 0px;
  margin: 0;
  top: 3px;
  bottom: 0;
  text-align: center;
  line-height: 40px;
}

// Botões menores junto ao floating button
.nd1 {
  // background: #00ddca;
  background: var(--color-accent);
  right: 40px;
  bottom: 120px;
  animation-delay: 0.2s;
  animation: bounce-out-nds 0.3s linear;
  animation-fill-mode: forwards;
}

// Botões menores junto ao floating button
.nd2 {
  // background: #00ddca;
  background: var(--color-accent);
  right: 40px;
  bottom: 180px;
  animation-delay: 0.15s;
  animation: bounce-out-nds 0.15s linear;
  animation-fill-mode: forwards;
}

// Botões menores junto ao floating button
.nd3 {
  //background: #00ddca;
  background: var(--color-accent);
  right: 40px;
  bottom: 240px;
  animation-delay: 0.1s;
  animation: bounce-out-nds 0.1s linear;
  animation-fill-mode: forwards;
}

// Animações floating buttons menores
@keyframes bounce-nds {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

// Animações floating buttons menores
@keyframes bounce-out-nds {
  from {
    opacity: 1;
    transform: scale(1);
  }
  to {
    opacity: 0;
    transform: scale(0);
  }
}

// Container dos floating buttons menores
#container-floating:hover .nds {
  animation: bounce-nds 0.1s linear;
  animation-fill-mode: forwards;
}

// Container dos floating buttons menores
#container-floating:hover .nd2 {
  animation-delay: 0.08s;
}
// Container dos floating buttons menores
#container-floating:hover .nd3 {
  animation-delay: 0.15s;
}

/* ---------- 6) Componentes e classes específicas da aplicação ---------- */

// Container do header para os botões // TODO: Tem que ver se vai ser necessário devido a como serão colocados os botões no header
.container-header {
  //margin: 0px;
  flex: 28%;
  display: flex;
  justify-content: center;
  margin-left: -110px; // para alinhar no meio considerando o logotipo do invoicecon
}

// Cabeçalho da aplicação
header {
  background-color: var(--color-bg-1);
  -webkit-box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.25);
  position: fixed;
  width: 100%;
  z-index: 10;
  margin: -8px;
  top: 8px;
}

// Tela de indicação de login
.login {
  //background-color: $color-primary;
  background-color: var(--color-bg-0);
  width: 100%;
  height: 97vh;
  display: flex;
}

// Container presente na tela de login
.card-login {
  //background-color: #27283c;
  //background-color: #282828;
  background-color: var(--color-bg-1);
  width: 18%;
  padding: 19px 73px;
  margin: auto;
}

.container-logo-login {
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 36px;
}

// Input de texto dentro dos cards
.card mat-form-field {
  margin-right: 15px;
}

// Rodapé dos cards dos processos
.card-footer {
  margin-top: 22px;
  float: right;
  width: 100%;
}

// Componente sintese processo
.sint-processo-geral {
  background-color: var(--color-bg-1);
  padding: 0.5em;
}

// Cabeçalho da tabela da sintese comparação e sintese aprovação
table.mat-table.inner-table thead th {
  font-size: 0.85em !important;
  color: var(--color-fg-0);
}

// Conteúdo da tabela da sintese comparação e sintese aprovação
table.mat-table.inner-table tbody td {
  font-size: 0.8em !important;
  color: var(--color-fg-0);
}

// Badge para itens que requerem atenção (ex. badge "Em revisão" colocado em um processo que está em revisão)
.badge-attention {
  background-color: var(--color-attention) !important;
  color: var(--color-bg-1) !important;
}

// Título do expansion panel dentro dos cards
.card .mat-expansion-panel-header-title {
  //font-size: 26px;
  font-size: 20px !important;
  //color: white;
  color: var(--color-font-main);
  font-weight: 500;
}

// Header do expansion panel dentro dos cards
.card .mat-expansion-panel-header {
  //border-bottom: 2px solid #c0c0c4;
  border-bottom: 1px solid var(--color-fg-3);
}

// Status de processo
.excluido {
  //background-color: #c4c0c0;
  background-color: var(--color-bg-3);
  color: var(--color-fg-2);
}

// Status de processo
.concluido {
  //background-color: rgb(105, 170, 21);
  background-color: var(--color-success);
  color: var(--color-fg-0);
}

// Status de processo
.aguardando-aprovacao {
  background-color: var(--color-attention);
  color: var(--color-fg-inv);
}

// Status de processo
.aguardando-processamento {
  // background-color: #007bff;
  // color: #007bff;
  background-color: var(--color-extras);
  color: var(--color-fg-0);
}

// Status de processo
.revisao {
  // background-color: #daa41e;
  background-color: var(--color-attention);
  color: var(--color-fg-inv);
}

// Status de processo
.erro {
  // background-color: #e41212;
  background-color: var(--color-warning);
  color: var(--color-fg-0);
}

// Badge geral (utilizado na tabela de comparação, por exemplo)
.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  width: 80%;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 10px;
}

// Badge de status na tabela da homescreen (aquela cor que indica o status do processo)
.badge.badge-table {
  display: inline-block;
  padding: 0.25em 0.4em;
  width: 2% !important;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 25px;
  height: 9px;
}

// Informações técnicas sobre o processo
.title-detail {
  //color: #707070;
  // color: #aaa;
  color: var(--color-fg-1);
}

// Label das informações resumidas dentro das etapas aprovação e escrituração
.title-detail2 {
  //color: #707070;
  // color: #aaa;
  color: var(--color-fg-1);
  font-size: 1em;
}

// Fonte da tabela principal na home
#grid-home .mat-header-cell {
  font-weight: 800;
}

// Ícone do relógio na tabela principal
mat-icon.mat-icon.clock {
  width: 16px;
}

// Tabela de comparação na sintese-comparação
.card-multiple-tables {
  width: 52%;
  text-align: left;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
  //background-color: #313248;
  //background-color: #393939;
  background-color: var(--color-bg-2);
  padding: 34px;
}

// Fonte do cabeçalho da tabela de comparaçaõ na sintese-comparação
.card-multiple-tables table thead th {
  //color: white;
  // color: #eee;
  color: var(--color-fg-0);
  font-weight: bold;
}

// Fonte do corpo da tabela de comparaçaõ na sintese-comparação
.card-multiple-tables table tbody td {
  //color: #a4a4a8;
  // color:#eee;
  color: var(--color-fg-0);
}

// Badge de outras informações na sintese comparação
.badge-0 {
  // background-color: #243b7c;
  background-color: var(--color-extras);
  // color: #eee0e0;
  color: var(--color-fg-light);
}

// Badge de comparação correta na sintese comparação
.badge-1 {
  // background-color: #10d441;
  background-color: var(--color-success);
  color: var(--color-fg-light);
}

// Badge de erro na comparação na sintese comparação
.badge-2 {
  // background-color: #8d121af8;
  background-color: var(--color-warning);
  // color: #eee0e0;
  color: var(--color-fg-light);
}

// Spinner
.spinner {
  text-align: -webkit-center;
}

// Botão fechar nos sintese-processo e modals
.back svg {
  cursor: pointer;
}

// Modal de upload de arquivo
.custom-modal-upload-box {
  padding: 0;
  //background: rgb(39, 40, 60);
  background-color: var(--color-bg-0);
  position: fixed;
  top: 25%;
  left: 25%;
}

// Backdrop sobre o fundo quando um modal é aberto
.custom-backdrop {
  background: var(--color-backdrop);
}

// Cotainer para a tabela de comparação de valores
.comp-table-container {
  width: 100%;
}

// Células pequenas na tabela de comparação
.comp-cell-sm {
  width: 30%;
  text-align: center;
}

// Células médias na tabela de comparação
.comp-cell-md {
  width: 40%;
  text-align: center;
}

// Células grandes na tabela de comparação
.comp-cell-lg {
  width: 60%;
  text-align: center;
}

// Classe geral para colocar reticências nos textos que não couberem no espaço alocado
.reducer {
  padding-right: 10px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

// Checkbox da tabela principal na home screen
.sel {
  display: flex;
  justify-content: flex-start;
  padding: 0px;
  margin: 0px;
}

// Classe base do modal da tela de busca referências e requisitantes
.modal-ref-container {
  //background-color: #1e1d2f;
  background-color: var(--color-bg-0);
  padding: 15px;
  flex-direction: column;
  box-sizing: border-box;
  display: flex;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
}

// Container do input de busca do modal referências
.modal-ref-search-container {
  height: 55px;
  min-height: 55px;
  margin-bottom: 2%;
  margin-top: 2%;
  background-color: var(--color-bg-0);
}

// Wrapper do input de busca do modal referências
.modal-ref-search-wrapper {
  width: 35%;
  margin-left: 0.8%;
  border: none;
}

// Container da tabela do modal referências
.modal-ref-table-container {
  width: 95%;
  margin: auto;
  border: none !important;
  //background-color: #27283c;
  background-color: var(--color-bg-1);
  padding: 0% 2% 2%;
  height: 80%;
  overflow-y: auto;
  overflow-x: hidden;
}

// Texto do header das tabelas do modal referências e requisitantes
.modal-ref-th {
  font-weight: bold;
  color: var(--color-fg-0);
}

// Footer do modal de referências e requisitante
.modal-ref-footer {
  height: 15%;
}

// Texto no footer do modal referência
.modal-ref-footer-txt {
  width: 100%;
  margin-bottom: 2%;
}

// Span texto do footer do modal referências
.modal-ref-footer-span {
  float: left;
}

// Botoes no footer do modal referências e requisitantes
.modal-ref-footer-btn {
  float: right;
  padding-left: 5%;
}

// Cor do texto da comparação de valores dos pedidos no modal referências (divergência nos valores)
.tdRed {
  color: var(--color-warning);
  font-weight: bold;
}

// Cor do texto da comparação de valores dos pedidos no modal referências (Valores corretos)
.tdGreen {
  color: var(--color-success);
  font-weight: bold;
}

// Container da tabela do modal de requisitantes
.modal-req-table-container {
  width: 95%;
  margin: auto;
  border: none !important;
  //background-color: #27283c;
  background-color: var(--color-bg-1);
  padding: 0% 2% 2%;
  overflow-y: auto;
  overflow-x: hidden;
  min-height: 0px !important;
  max-height: 90% !important;
}

// Fonte cabeçalho da tabela do modal requisitante
table.mat-table.table-modal-requisitante thead th {
  color: var(--color-fg-0) !important;
  font-size: 0.8em !important;
}

// Fonte conteúdo da tabela do modal requisitante
table.mat-table.table-modal-requisitante tbody td {
  color: var(--color-fg-0) !important;
  font-size: 0.8em !important;
}

// Cabeçalho da tabela de logs
table.mat-table.log-table thead th {
  font-size: 0.95em !important;
  color: var(--color-fg-0);
}

// Conteúdo da tabela de logs
table.mat-table.log-table tbody td {
  font-size: 0.95em !important;
  color: var(--color-fg-0);
}

// Fundo da opção do menu de usuario
.mat-menu-item-highlighted:not([disabled]),
.mat-menu-item.cdk-keyboard-focused:not([disabled]),
.mat-menu-item.cdk-program-focused:not([disabled]),
.mat-menu-item:hover:not([disabled]) {
  background-color: var(--color-bg-2);
}

// Icones com cores inversas ao fundo principal
.icon-inv {
  color: var(--color-icon-inv) !important;
}

.icon-inv svg {
  color: var(--color-icon-inv) !important;
  fill: var(--color-icon-inv) !important;
}

// Container da página de erro
.container-erro-page {
  width: 100%;
  height: 100%;
  padding: 10% 0% 10% 0%;
}

// Container centralizado na tela
.container-erro-main {
  width: 50%;
  min-width: 400px;
  max-width: 700px;
  height: 40%;
  min-height: 200px;
  margin: auto;
  padding-top: 10px;
}

// Painel com texto e imagem
.erro-panel {
  background-color: var(--color-bg-1);
  border-radius: 10px;
  border: 1px var(--color-bg-1) solid;
}

// Container dos filtros
.container-filtros {
  height: 80vh;
  overflow-y: scroll;
  overflow-x: hidden;
}

// Container dos filtros de datas na home
.container-filtros-data {
  outline: 0;
  border: 0;
  border-bottom: 2px solid var(--color-fg-3) !important;
}

// Container principal da tela de dashboards
.dashboard-container {
  width: 100%;
  height: 100%;
  overflow: auto;
}

// Container para o spinner do dashboard
.dashboard-spinner-container {
  display: block;
  position: fixed;
  z-index: 1031;
  top: 47%;
  right: 47%;
}

// Container dos gráficos superiores do dashboard
.dashboard-row-container-top {
  margin: 0px auto;
  margin-top: 30px;
}

// Container dos gráficos inferiores do dashboard
.dashboard-row-container-bottom {
  margin: 0px auto;
}

// Container para o gráfico em si
.dashboard-chart-container {
  margin: 20px auto;
  width: 50%;
  height: 40%;
  cursor: pointer;
}

// Container base para o modal de um set de gráficos
.dashboard-modal-container {
  width: 100%;
  height: 93%;
  overflow-x: auto;
  background-color: var(--color-bg-0);
  margin: 0px;
  padding: 0px;
}

// Container área dos gráficos no modal
.dashboard-modal-column-container {
  padding: 0px;
}

// Container área do footer dos modals dashboard
.dashboard-modal-footer-container {
  width: 100%;
  height: 7%;
  overflow-x: auto;
  background-color: var(--color-bg-0);
  margin: 0px;
  padding: 0px;
}

// Footer do modal do dashboard
.dashboard-modal-footer {
  margin: 0px auto;
}

// Container dos gráficos superiores do modal dashboard
.dashboard-modal-row-container-top {
  margin: 0px auto;
  margin-bottom: 10px;
}

// Container dos gráficos inferiores do modal dashboard
.dashboard-modal-row-container-bottom {
  margin: 0px auto;
}

// Container do card existente no modal1
.dashboard-modal1-card-container {
  padding-top: 30px;
  text-align: center;
}

// Card existente no modal1
.dashboard-modal1-card {
  width: 200px;
  background-color: var(--color-bg-1);
  margin-top: 30px;
  height: 150px;
}

// Texto grande no modal dashboard
.dashboard-card-text-xl {
  font-size: 48px;
  color: var(--color-fg-0);
}

// Texto pequeno no modal dashboard
.dashboard-card-text-sm {
  font-size: 16px;
  color: var(--color-fg-0);
}

// Container pequeno para gráficos no modal dashboard
.dashboard-modal-chart-sm-container {
  margin: 0px auto;
  width: 50%;
  height: 100%;
}

// Container grande para gráficos no modal dashboard
.dashboard-modal-chart-xl-container {
  margin: 0px auto;
  width: 100%;
  height: 100%;
}

// Container para gráficos no modal4 dos dashboards
.dashboard-modal4-column-container {
  padding: 0px;
  height: 100%;
}

// Aparência na tabela da home screen dos processos excluidos
.linha-excluida {
  opacity: 0.4 !important;
}

// Botões extras (como busca referências ou busca requisitantes)
.button-icon-selecao {
  width: 16px !important;
  height: 16px !important;
  margin-bottom: 1em !important;
}

// Seção principal para quando o perfil não tem autorização
.container-main-profile-auth {
  width: 100%;
  height: 100%;
  padding: 10% 0% 10% 0%;
}

// Seção interna para quando o perfil não tem autorização
.container-inner-profile-auth {
  width: 50%;
  min-width: 400px;
  height: 40%;
  min-height: 200px;
  margin: auto;
  padding-top: 10px;
}

// Container da mensagem que aparece quando usuário não tem acesso à etapa
.container-no-access {
  background-color: var(--color-transparent);
}

/* Change Autocomplete styles in Chrome*/
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-transition-delay: 99999s;
  transition-delay: 99999s;
}

//Loading
.mat-progress-spinner circle,
.mat-spinner circle {
  stroke: var(--color-accent);
}

//Seta menu
.mat-menu-item .mat-icon-no-color,
.mat-menu-item-submenu-trigger::after {
  color: rgba(255, 255, 255, 0.54);
}

//Icone Pesquisar
mat-icon {
  color: var(--color-fg-0);
}

//Icone Fechar Modal
mat-icon[aria-label="Close"] {
  color: var(--color-fg-3);
}

//Conteúdo
.mat-dialog-container,
.mat-drawer-container {
  color: var(--color-bg-3);
}

.button-small {
  font-size: 14px;
  margin-bottom: 5px;
  color: var(--color-fg-0) !important;
  background-color: #fff0 !important;
  border: 1px solid var(--color-fg-0) !important;
}

.button-delete {
  padding: 17px;
  cursor: pointer;
}

.pd-10 {
  padding: 10px !important;
}

.mb-10 {
  margin-bottom: 10px !important;
}

/** ---------- INCERTOS ---------- **/

// TODO: Ver se isso aqui vai ser preciso
// card
// body {
//   //background-color: $color-primary;
//   background-color: var(--color-layer-0);
//   overflow-x: hidden;
// }

// TODO ver se vai precisar
// span {
//   //color: #adacb2;
//   //color: #eee;
//   color: var(--color-font-main);
//   font-family: Roboto, "Helvetica Neue", sans-serif;
// }

// TODO ver se vai precisar
// .title {
//   font-weight: bold;
// }

// TODO: Ver se isso vai ser necessário
// mat-form-field.mat-form-field-type-mat-native-select .mat-form-field-infix::after {
//   //color: white;
//   color: var(--color-fg-0);
// }

// TODO: Ver se isso vai ser necessário
// .mat-form-field-label-wrapper .mat-form-field-label mat-label {
//   //color: #8e8e8e;
//   color: var(--color-fg-2);
//   font-size: 18px !important; //teste
// }

// TODO: Ver se isso vai ser necessário
// mat-form-field.mat-form-field.required.mat-form-field-appearance-legacy .mat-form-field-underline {
//   //background-color: red;
//   background-color: var(--color-warning);
// }

// TODO: Ver se isso vai ser utilizado
// Botão do cabeçalho
.mat-button.button-header {
  color: var(--color-fg-3);
  height: 52px;
  display: inline-block;
  width: 10%;
  min-width: 100px;
}

// TODO: ver se isso vai ser utilizado
// Hover no botão do cabeçalho
.mat-button.button-header:hover {
  background-color: var(--color-bg-3);
}

// Estilos
mat-label,
mat-slide-toggle {
  font-weight: bold;
  color: var(--color-label);
  font-size: 16px;
}

.panel-title {
  color: var(--color-fg-0);
  font-weight: 600;
  line-height: 1.5rem;
  letter-spacing: 0.009rem;
}

.button-small {
  font-size: 14px;
}

.button-delete {
  padding: 17px;
  cursor: pointer;
}

.button-delete mat-icon {
  color: var(--color-warning);
}

.pd-10 {
  padding: 10px;
}

.mb-10 {
  margin-bottom: 10px;
}
.mr-10 {
  margin-right: 10px;
}

.invalidHeader,
.invalidHeader.mat-expansion-panel-header.mat-expanded:focus,
.invalidHeader.mat-expansion-panel-header.mat-expanded:hover,
.mat-expansion-panel:not(.mat-expanded)
  .invalidHeader.mat-expansion-panel-header:hover {
  background: var(--color-warning);
}

.invalidHeader span mat-panel-title,
.invalidHeader span mat-panel-description mat-icon,
.invalidHeader .mat-expansion-indicator::after {
  color: white !important;
}

.header-fg {
  background-color: var(--color-fg-3);
  padding: 5px;
}

.spacer {
  flex: 1 1 auto;
}

.mat-toolbar {
  background: var(--color-toolbar) !important;
  border-radius: 5px;
  color: white !important;
}

.white {
  color: white !important;
}

.stroked-span {
  padding-left: 5px;
  font-weight: bold;
}

.button-toolbar {
  border: 1px solid white !important;
}

.button-toolbar:disabled {
  border: 1px solid #ababab !important;
}

.button-toolbar:disabled > .white,
.button-toolbar:disabled > .mdc-button__label span {
  color: #ababab !important;
}

.title-toolbar {
  padding-left: 40px;
  font-size: 23px;
}

.container-drawer {
  width: auto;
  height: auto;
  min-height: 600px;
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 5px;
  /* The background property is added to clearly distinguish the borders between drawer and main
     content */
  background: var(--color-drawer);
}

.container-drawer mat-drawer {
  background: var(--color-bg-1);
}

.container-content {
  background-color: var(--color-drawer) !important;
  padding: 10px;
}

.mat-expansion-panel.color-inv,
.mat-card.color-inv {
  background: var(--color-card);
  color: var(--color-fg-0);
}

// Descritivo dos expansion panels e setinha
.mat-expansion-indicator::after,
.color-inv.mat-expansion-panel-header-description {
  color: var(--color-fg-0);
}

.mat-card-subtitle,
.mat-hint,
.mat-input-element::placeholder {
  color: var(--color-subtitle);
}

.mat-form-field-appearance-legacy.mat-form-field-disabled
  .mat-form-field-underline {
  background-image: linear-gradient(
    to right,
    var(--color-drawer) 0%,
    var(--color-drawer) 33%,
    transparent 0%
  );
  background-size: 7px 100%;
  background-repeat: repeat-x;
}

//  TODO: Ver se isso vai ser necessário
.selected {
  background-color: var(--color-bg-2) !important;
}

.mat-mdc-outlined-button > .mat-icon {
  margin-right: 0px !important;
  margin-left: 0px !important;
}

.mat-mdc-outlined-button {
  border: 1px solid;
}

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 30px var(--color-bg-0) inset !important;
}

input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px var(--color-bg-2) inset !important;
}

.btn-suffix{
  background-color: #fff0 !important;
}
